import React from "react"
import styled from "styled-components"
import { Section } from "../shared/Layout/components/Section/Section"
import ContactInfo from "./ContactInfo"
import ContactMap from "./ContactMap/ContactMap"

const StyledMapSection = styled(Section)`
  padding-bottom: 0;
`
const MapSection = () => (
  <StyledMapSection
    style={{
      paddingBottom: 0,
    }}
  >
    <ContactInfo />
    <ContactMap />
  </StyledMapSection>
)

export default MapSection
