import styled from "styled-components"

export const Stripe = styled.div`
  height: 100%;
  max-height: 200px;
  background-color: ${({ theme }) => theme.color.primary};
  padding: 0 40px;
  margin-bottom: 50px;
  
    /* @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) { 
    max-height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  } */
`
// export const Stripe: React.FC<Props> = (props) => {
//   return <_Stripe>{props.children}</_Stripe>
// }

// export default Stripe
