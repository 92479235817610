export interface paymentInfoI {
  title: string
  description: string
}

export const paymentInfo: paymentInfoI[] = [
  {
    title: "IBAN:",
    description: "SK79 7500 0000 0040 2822 2578",
  },
  {
    title: "Variabilný symbol (VS):",
    description: "Dátum narodenia",
  },
  {
    title: "Poznámka:",
    description: "Meno a priezvisko uchádzača o vodičské oprávnenie",
  },
]
