import React from "react"
import styled from "styled-components"

const CustomLink = styled.a`
  font-size: 20px;
`
interface ContactWrapperProps {
  color: string
}

const Number = styled.span<ContactWrapperProps>`
  font-size: 17px;
  color: ${({ color }) => color};
  margin: 0px;
`

const ContactWrapper = styled.span<ContactWrapperProps>`
  color: ${({ color }) => color};
  margin-bottom: 16px;
`

const contacts = [
  {
    instructor: "Michal Hrončiak",
    tel: "0908 060 101",
    color: "#ec1919",
  },
  {
    instructor: "Ján Hrončiak ",
    tel: "0903 955 999",
    color: "#1328e2",
  },
  {
    instructor: "Tomáš Hrončiak",
    tel: "0915 306 992",
    color: "#069806",
  },
]

const ContactsComponent = () => (
  <div>
    {contacts.map((contact, index) => (
      <ContactWrapper key={index} color={contact.color}>
        {contact.instructor}:{" "}
        <a href={"tel:" + contact.tel}>
          <Number color={contact.color}>{contact.tel}</Number>
        </a>
        <br />
      </ContactWrapper>
    ))}
  </div>
)

const emailAddress = "info@autoskolahron.sk"
const EmailComponent = () => (
  <CustomLink href={`mailto: ${emailAddress}`}>{emailAddress}</CustomLink>
)

const schoolAddress = {
  place: "Medzi Hrušky 2865/10, 962 21 Lieskovec",
}

const SchoolAddressComponent = () => (
  <CustomLink>{schoolAddress.place}</CustomLink>
)

const classAddress = {
  place: "Centrum voľného času Domino Bela IV. 1567/6 960 01 Zvolen",
  mapLink:
    "https://www.google.com/maps/place/Centrum+vo%C4%BEn%C3%A9ho+%C4%8Dasu+Domino/@48.5766841,19.1373141,19.94z/data=!4m5!3m4!1s0x47153821cb360345:0xc2ffef16e0193462!8m2!3d48.5764109!4d19.1374836",
}

const ClassAddressComponent = () => (
  <CustomLink
    target="_blank"
    href={classAddress.mapLink}
    rel="noopener noreferrer"
  >
    {classAddress.place}
  </CustomLink>
)

export const contactInfos = [
  {
    name: "Adresa autoškoly",
    render: SchoolAddressComponent,
  },
  {
    name: "Učebňa teórie",
    render: ClassAddressComponent,
  },

  {
    name: "Email",
    render: EmailComponent,
  },

  {
    name: "Kontakt",
    render: ContactsComponent,
  },
]
