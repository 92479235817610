import React from "react"
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps"
import styled from "styled-components"
import Container from "../../shared/Layout/components/Container/container"

const MarkerIcon = require("./pin.svg")

const MapWrapper = styled(Container)`
  height: 725px;
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 0;
`

interface Props {}

const MyMapComponent = withScriptjs(
  withGoogleMap((props: any) => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: 48.576581, lng: 19.137484 }}
    >
      {props.isMarkerShown && (
        <Marker
          position={{ lat: 48.576581, lng: 19.137484 }}
          icon={MarkerIcon}
        />
      )}
    </GoogleMap>
  ))
) as any

const ContactMap: React.FC<Props> = (props) => {
  return (
    <>
      <header></header>
      <MapWrapper
        fluid={true}
        data-sal-duration="650"
        data-sal="fade"
        data-sal-easing="ease-in"
      >
        <MyMapComponent
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBpg7gOHO_jF3wGQkV3GV26cuO5KlsFCbM&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </MapWrapper>
    </>
  )
}
export default ContactMap
