import Grid from "@material-ui/core/Grid"
import React from "react"
import styled from "styled-components"
import Card from "../shared/Card"
import Container from "../shared/Layout/components/Container/container"
import { Stripe } from "../shared/Layout/components/Stripe/Stripe"
import { contactInfos } from "./ContactInfo/info"

const InfoboxName = styled.span`
  font-size: 20px;
  margin-bottom: 11px;
`
const InfoboxInfo = styled.h3`
  font-size: 17px;
  line-height: 24px;
  a {
    font-size: 17px;
  }
`
const StyledContainer = styled(Container)`
  position: relative;
  z-index: 10;
`
const StyledStripe = styled(Stripe)`
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-height: 100%;
    padding: 30px 15px;
  }
`
const InfoBox = styled(Grid)`
  margin: auto 0;
  height: 100%;
`

const CustomBox = styled(Grid)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-bottom: 30px !important;
  }
`

const ContactInfo = () => (
  <StyledStripe>
    <StyledContainer fluid={false}>
      <Grid container spacing={2} direction="row">
        {contactInfos.map((infobox, index) => (
          <CustomBox key={index} item lg={3} md={3} sm={12} xs={12} className="mybox">
            <Card
              style={{
                padding: "20px",
                height: "100%",
              }}
              data-sal-duration="650"
              data-sal="fade"
              data-sal-easing="ease-in"
              data-sal-delay={`${index + 1 * 2}00`}
            >
              <InfoBox container justify="center" direction="column">
                <InfoboxName className="upTitle">{infobox.name}</InfoboxName>
                <InfoboxInfo>{infobox.render()}</InfoboxInfo>
              </InfoBox>
            </Card>
          </CustomBox>
        ))}
      </Grid>
    </StyledContainer>
  </StyledStripe>
)

export default ContactInfo
