import React from "react"

import styled from "styled-components"
import { paymentInfo } from "./paymentInfo"

const Wrapper = styled.div`
  text-align: center;
  padding-bottom: 20px;

  p {
    span {
      font-weight: bold;
    }
  }
`
const Intro = styled.div`
  margin-bottom: 50px;
  height: 100%;
  &.text-center {
    text-align: center;
  }
`

const ContactSro = () => (
  <Wrapper data-sal-duration="650" data-sal="fade" data-sal-easing="ease-in">
        <Intro  className="text-center">
            <span className="upTitle">Kontaktné informácie</span>
            <h2>Autoškola Hron s.r.o.</h2>
          </Intro>
    <h3>Úhrada na bankový účet</h3>
    {paymentInfo.map((info) => (
      <p>
        <span>{info.title}</span> {info.description}
      </p>
    ))}
  </Wrapper>
)

export default ContactSro
