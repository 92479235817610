import Grid from "@material-ui/core/Grid"
import React from "react"
import styled from "styled-components"
import ContactSro from "../components/Contact/ContactInfo/ContactSro"
import MapSection from "../components/Contact/MapSection"
import SendMessageForm from "../components/Contact/SendMessageForm/SendMessageForm"
import SEO from "../components/seo"
import Container from "../components/shared/Layout/components/Container/container"
import { Section } from "../components/shared/Layout/components/Section/Section"
import Layout from "../components/shared/Layout/layout"
import { PageHeader } from "../components/shared/PageHeader/PageHeader"

const FormSection = styled(Section)`
  position: relative;
`
const TimelineSection = styled(Section)`
  padding-bottom: 0;
`
const Intro = styled.div`
  margin-bottom: 50px;
  height: 100%;
  &.text-center {
    text-align: center;
  }
  &.intro-form {
    padding-top: 20%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-top: 10px;
    }
  }
`
const BackSquare = styled.div`
  height: 550px;
  background-color: ${({ theme }) => theme.color.secondaryBG};
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 60%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`

const ContactPage = () => (
  <Layout fluid={true}>
    <SEO
      title="Kontakt"
      description="Sme autoškola, ktorá vás naučí jazdiť, kontaktujte nás. Autoškola Hron"
      keywords="autoškola Hron kontakt, autoškola Hron číslo, autoškola Hron adresa, autoškola Hron email, Zvolen autoškola kontakt, Zvolen autoškola, autoskola zvolen, autoškola Zvolen"
    />
    <Section style={{ paddingBottom: 0 }}>
      <Container fluid={false}>
        <PageHeader header="Kontakt" subheader="Kontaktuj nás" />
      </Container>
      <FormSection>
        <BackSquare />
        <Container fluid={false}>
          <Grid container justify="center" alignItems="stretch">
            <Grid item xs={12} sm={4}>
              <Intro
                className="intro-form"
                data-sal-duration="650"
                data-sal="fade"
                data-sal-easing="ease-in"
                data-sal-delay="150"
              >
                <span className="upTitle">Autoškola Hron</span>
                <h2>Sme autoškola, ktorá ťa naučí jazdiť</h2>
              </Intro>
            </Grid>
            <Grid item xs={12} sm={8}>
              <SendMessageForm />
            </Grid>
          </Grid>
        </Container>
      </FormSection>
      <TimelineSection>
        <Container fluid={false}>
          <ContactSro />
        </Container>
        <MapSection />
      </TimelineSection>
    </Section>
  </Layout>
)

export default ContactPage
