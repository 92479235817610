import { CircularProgress, Grid } from "@material-ui/core"
import { Button } from "@material-ui/core/"
import FormControl from "@material-ui/core/FormControl"
import { Field, Form, Formik, FormikHelpers as FormikActions } from "formik"
import { CheckboxWithLabel, TextField } from "formik-material-ui"
import React from "react"
import { useMutation } from "react-query"
// import Container from "../../shared/Layout/components/Container/index"
import styled from "styled-components"
import Swal from "sweetalert2"
import * as Yup from "yup"
import api from "../../../lib/api/api"
import Card from "../../shared/Card"

const FormWrapp = styled.div`
  background-color: white;
`

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

interface FormValues {
  firstName: string
  lastName: string
  email: string
  phone: string
  agreement: boolean
}

export const getReadableFormValues = (values: any) => {
  let result = ``
  Object.keys(values).forEach((key) => {
    result += `<b>${key}</b>: ${values[key]} <br/>`
  })
  return result
}

const SendMessageForm: React.FC = () => {
  const mutation = useMutation(async (values: any) => {
    const { data } = await api({
      method: "POST",
      url: `/contact-form-submissions`,
      data: values,
    })

    await api({
      method: "POST",
      url: `/contact-form-submissions/email`,
      data,
    })
  })
  return (
    <Card
      style={{ maxWidth: "650px", margin: " 0 auto" }}
      data-sal-duration="650"
      data-sal="slide-up"
      data-sal-easing="ease-in"
      data-sal-delay="550"
    >
      <FormWrapp>
        <h2>Napíš nám</h2>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
            agreement: false,
          }}
          onSubmit={(values: FormValues, { setSubmitting, setStatus, resetForm }: FormikActions<FormValues>) => {
            mutation.mutateAsync(values).then(() => {
              Swal.fire("Správa úspešne odoslaná!", "", "success")
              resetForm({})
              setStatus({ success: true })
            })
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required('Políčko "Meno" je povinné.'),
            lastName: Yup.string().required('Políčko "Priezvisko" je povinné.'),
            email: Yup.string()
              .email('Políčko "Email" musí byť validné (obsahovať @).')
              .required('Políčko "Email" je povinné.'),
            phone: Yup.string()
              .required('Políčko "Telefon" je povinné.')
              .matches(phoneRegExp, 'Políčko "Telefón" nie je validné.'),
            agreement: Yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
            message: Yup.string().required('Políčko "Správa" je povinné.'),
          })}
        >
          {(formikBag, props) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <Field
                    style={{
                      width: "100%",
                    }}
                    component={TextField}
                    id="firstName"
                    name="firstName"
                    placeholder="Meno"
                    type="text"
                    label="Meno"
                  ></Field>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    style={{
                      width: "100%",
                    }}
                    component={TextField}
                    id="lastName"
                    name="lastName"
                    placeholder="Priezvisko"
                    type="text"
                    label="Priezvisko"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    style={{
                      width: "100%",
                    }}
                    component={TextField}
                    id="email"
                    name="email"
                    placeholder="meno@mail.com"
                    type="email"
                    label="Email"
                    // value={values.email}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    style={{
                      width: "100%",
                    }}
                    component={TextField}
                    id="phone"
                    name="phone"
                    placeholder="Telefón"
                    type="text"
                    label="Telefón"
                  />
                </Grid>
                <Grid item md={12} xs={12} container justify="flex-start">
                  <FormControl
                    style={{
                      width: "100%",
                    }}
                  >
                    <Field
                      style={{
                        width: "100%",
                      }}
                      component={TextField}
                      label="Správa"
                      name="message"
                      id="message"
                      multiline
                      rows={4}
                      rowsMax="16"
                    />
                  </FormControl>
                </Grid>
                <Grid item container md={12} xs={12} justify="flex-start">
                  <Field
                    component={CheckboxWithLabel}
                    name="agreement"
                    id="agreement"
                    Label={{ label: "Súhlasím so spracovaním osobných údajov" }}
                    value="false"
                    control="checkbox"
                    color="primary"
                  />
                </Grid>
                <Grid item container md={12} xs={12} justify="flex-start">
                  <Button type="submit" color="primary" variant="contained" className="upper">
                    Poslať správu
                    {mutation.isLoading && <CircularProgress color="secondary" />}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormWrapp>
    </Card>
  )
}

export default SendMessageForm
